<!-- eslint-disable vue/no-template-shadow -->
<!-- eslint-disable vue/singleline-html-element-content-newline -->
<!-- eslint-disable vue/max-attributes-per-line -->
<!-- eslint-disable max-len -->
<!--
 * @FilePath: /版权-提交端/baidu/feedback/copyright/src/main/resources/copyright/src/views/Complaint.vue
 * @Description: 填写投诉链接页面
 *
 * @Author: jiangshengkai@baidu.com
 * @Date: 2023-01-13 16:31:17
 * @LastEditors: v_yerun v_yerun@baidu.com
 * @LastEditTime: 2024-01-11 10:19:28
-->
<template>
    <div class="complaint">
        <main-steps :active="2"></main-steps>
        <div class="complaint-wrap">
            <div class="wrap-title">
                <i></i>
                <h1>填写投诉材料</h1>
            </div>
            <ul class="wrap-tips">
                <li>名称：{{ pdata.name }}</li>
                <li>类型：{{ pdata.type }}</li>
                <li>
                    <span v-show="pdata.id">ID：{{ pdata.id }}</span>
                </li>
            </ul>
            <div class="wrap-item line">
                <p><span class="required-icon">*</span>投诉产品</p>
                <el-select v-model="value" placeholder="请选择" @change="changeProduct">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        <img :src="item.img" alt="" style="width: 20px; height: 20px; position: relative; top: 5px" />
                        <span style="margin-left: 10px">{{ item.label }}</span>
                    </el-option>
                </el-select>
            </div>
            <div class="wrap-item">
                <p><span class="required-icon">*</span>投诉问题描述</p>
                <div class="right">
                    <el-input
                        v-model="textarea"
                        type="textarea"
                        :rows="5"
                        placeholder="请详细描述您遇到的问题并指出侵权理由，建议在200字以内"
                        maxlength="200"
                        show-word-limit
                    >
                    </el-input>
                </div>
            </div>
            <div class="wrap-item line" v-show="tortShow">
                <p>侵权类型</p>
                <el-select v-model="tortVal" placeholder="请选择">
                    <el-option :key="item.value" v-for="item in tortType" :label="item.label" :value="item.value">
                        <span style="margin-left: 10px">{{ item.label }}</span>
                    </el-option>
                </el-select>
            </div>
            <div class="wrap-item original">
                <p><span class="required-icon">*</span>原版链接</p>
                <div class="right">
                    <div class="link-list">
                        <el-form ref="actualRuleForm" :rules="originalLinkRules" :model="originalLinkCenter" hide-required-asterisk>
                            <el-form-item class="inp-name" label=" " prop="actualName">
                                <el-input v-model.trim="originalLinkCenter.actualName" placeholder="请输入链接标题"></el-input>
                            </el-form-item>
                            <el-form-item class="inp-url" label=" " prop="actualUrl">
                                <el-input
                                    v-model.trim="originalLinkCenter.actualUrl"
                                    placeholder="请输入链接地址"
                                    @blur="originalLinkBlur(originalLinkCenter)"
                                >
                                    <template slot="prepend"> http:// </template>
                                </el-input>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
            </div>
            <div class="wrap-item">
                <div class="top">
                    <p><span class="required-icon">*</span>侵权链接</p>
                    <p class="clear" @click="clearLink"><i></i>清除重复链接</p>
                </div>
                <div class="bottom">
                    <div class="add-link" @click="addLink">
                        <span></span>
                        <span>添加被投诉的链接</span>
                    </div>
                    <ul class="link-list">
                        <li v-for="item in currLink" :key="item.id">
                            <el-form ref="ruleForm" :rules="rules" :model="item" hide-required-asterisk>
                                <el-form-item class="inp-name" label=" " prop="url_name">
                                    <el-input v-model.trim="item.url_name" placeholder="请输入链接标题"></el-input>
                                </el-form-item>
                                <el-form-item class="inp-url" label=" " prop="url">
                                    <el-input
                                        v-model.trim="item.url"
                                        placeholder="请输入链接地址"
                                        @blur="blur(item.id)"
                                    >
                                        <template slot="prepend"> http:// </template>
                                    </el-input>
                                </el-form-item>
                                <i @click="delLink(item.id)"></i>
                            </el-form>
                        </li>
                        <el-pagination
                            v-show="linkList.length > showRows"
                            background
                            layout="prev, pager, next"
                            :page-size="showRows"
                            :current-page="cpage"
                            :total="linkList.length"
                            @current-change="changeC"
                        >
                        </el-pagination>
                        <el-divider></el-divider>
                    </ul>
                </div>
            </div>
            <div class="wrap-item">
                <div class="upload">
                    <p class="title">批量上传被投诉的链接</p>
                    <el-upload
                        ref="upload"
                        class="upload-demo"
                        :action="baseUrl + '/upload/excel'"
                        :on-preview="handlePreview"
                        :on-remove="handleRemove"
                        :file-list="fileList"
                        :on-exceed="handleExceed"
                        accept=".xlsx, .xls"
                        :on-success="handleFileSuccess"
                        :auto-upload="true"
                        :before-upload="beforeAvatarUpload"
                        :show-file-list="false"
                    >
                        <el-button slot="trigger" size="small" type="primary"> 选择文件 </el-button>
                        <div slot="tip" class="el-upload__tip">
                            <div slot="tip" class="el-upload__tip">
                                <p>如您通过上传文件的形式上传待处理链接，请您按要求上传excel格式的文件</p>
                                <p>（第1行作为表头，A列为序号、B列为链接名称、C列为链接地址，单次最多可</p>
                                <p>
                                    以提交200条链接，超过的请分批投诉）,
                                    <a
                                        href="https://copyright.bj.bcebos.com/%E7%89%88%E6%9D%83%E6%8A%95%E8%AF%89%E9%93%BE%E6%8E%A5%E9%99%84%E4%BB%B6%E6%A8%A1%E7%89%88.xls?authorization=bce-auth-v1/f1587d23db6b446eaa9c2aa9c3619102/2021-08-02T13%3A08%3A54Z/-1/host/38acaae72ca662c3ed8a5ccd5f29244c6c9c64f108243b5dd6281370343adcb7"
                                    >
                                        下载Excel模版
                                    </a>
                                </p>
                            </div>
                        </div>
                    </el-upload>
                </div>
            </div>
            <div class="next-btn">
                <next-tick :btn1="0" :btn2="0" :btn3="1" :btn4="1" @nextTick="next"></next-tick>
            </div>
        </div>
    </div>
</template>
<script>
import MainSteps from '@/views/MainSteps.vue';
import NextTick from '@/components/NextTick.vue';
import { getType } from '@/utils/public.js';
import product from '@/assets/data/product.js';
import { checkUrlList } from '@/api/getData.js';
export default {
    components: {
        'main-steps': MainSteps,
        'next-tick': NextTick,
    },
    data() {
        var validatorUrl = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请填写链接信息'));
            } else if (this.urlExceptionList && this.urlExceptionList.includes(value)) {
                callback(new Error(this.checkUrlOwnMessage));
            } else {
                callback();
            }
        };
        return {
            /**
             * @description: 后端监测异常的URL列表
             * @return {*}
             */
            urlExceptionList: [],

            /**
             * @description: URL检测提示消息
             * @return {*}
             */
            checkUrlOwnMessage: '',

            fileList: [],
            fileName: '',
            baseUrl: process.env.NODE_ENV === 'production' ? location.origin : '/proxy',
            pdata: {},
            limit: '1',
            item: {
                url_name: '',
                url: '',
            },
            rules: {
                url_name: [
                    // 投诉链接名称
                    {
                        required: true,
                        message: '请填写链接信息',
                        trigger: 'blur',
                    },
                    {
                        min: 1,
                        max: 500,
                        message: '长度在 500 个字符以内',
                        trigger: 'blur',
                    },
                ],
                url: [
                    // 投诉链接规则
                    // { required: true, message: '请填写链接信息', trigger: 'blur' },
                    {
                        required: true,
                        validator: validatorUrl,
                        trigger: 'blur',
                    },
                    {
                        min: 1,
                        max: 1000,
                        message: '长度在 1000 个字符以内',
                        trigger: 'blur',
                    },
                ],
            },
            // 原版链接内容
            originalLinkCenter: {
                actualName: '',
                actualUrl: '',
            },
            // 原版链接效验规则
            originalLinkRules: {
                actualName: [
                    // 原版链接名称
                    {
                        required: true,
                        message: '请填写链接信息',
                        trigger: 'blur',
                    },
                    {
                        min: 1,
                        max: 500,
                        message: '长度在 500 个字符以内',
                        trigger: 'blur',
                    },
                ],
                actualUrl: [
                    // 原版链接
                    {
                        required: true,
                        message: '请填写链接信息',
                        trigger: 'blur'
                    },
                    {
                        min: 1,
                        max: 1000,
                        message: '长度在 1000 个字符以内',
                        trigger: 'blur',
                    },
                ],
            },
            newUploadForm: {
                password: '',
                fileName: '',
            },
            id: 1,
            linkList: [], // 权限链接--总数据
            currLink: [], // 权限链接--当前显示的数据
            showRows: 12, // 权限链接--每页显示的条数
            cpage: 1, // 权限链接--当前页
            tortType: [
                {
                    value: 1,
                    label: '影视版权',
                },
                {
                    value: 2,
                    label: '综艺版权',
                },
                {
                    value: 3,
                    label: '动漫动画版权',
                },
                {
                    value: 4,
                    label: '音乐版权',
                },
                {
                    value: 5,
                    label: '游戏版权',
                },
                {
                    value: 6,
                    label: '体育赛事版权',
                },
                {
                    value: 7,
                    label: '新闻媒体版权',
                },
                {
                    value: 8,
                    label: '自媒体版权',
                },
            ],
            options: [
                {
                    value: 1,
                    label: '百度app',
                },
                {
                    value: 2,
                    label: '其他',
                },
                {
                    value: 1301,
                    label: '我是1301',
                },
            ],
            value: '',
            tortVal: '',
            icon: null,
            tortShow: false,
            textarea: '',
            params: this.$route.params,
        };
    },
    methods: {
        changeProduct() {
            if (this.value === 1408 || this.value === 1409) {
                this.tortShow = true;
            } else {
                this.tortShow = false;
            }
        },
        fn(e) {
            this.options.forEach((item) => {
                if (e === item.value) {
                    this.icon = item.img;
                }
            });
        },
        /**
         * @description: 原版链接输入填充
         * @param {*} item
         * @return {*}
         */
        originalLinkBlur(item) {
            if (item.actualUrl === '') {
                return;
            }
            let titleUrl = item.actualUrl.substring(0, 7);
            let titleUrl1 = item.actualUrl.substring(0, 8);
            if (titleUrl !== 'http://' && titleUrl1 !== 'https://') {
                item.actualUrl = 'http://' + item.actualUrl;
            } else {
                return;
            }
        },
        blur(id) {
            this.linkList.forEach((item) => {
                if (item.id === id) {
                    if (item.url === '') {
                        return;
                    }
                    let titleUrl = item.url.substring(0, 7);
                    let titleUrl1 = item.url.substring(0, 8);
                    // eslint-disable-next-line no-empty
                    if (titleUrl === 'http://' || titleUrl1 === 'https://') {
                    } else {
                        item.url = 'http://' + item.url;
                    }
                }
            });
            this.currLink = this.linkList.filter((item, index) => {
                return index < this.showRows;
            });
        },
        next() {
            let actualRuleForm = false;
            let ruleFrom = false;
            this.$refs.actualRuleForm.validate((valid) => {
                if (valid) {
                    actualRuleForm = true;
                } else {
                    actualRuleForm = false;
                }
            });
            this.$refs.ruleForm?.forEach((item) => {
                item.validate(valid => {
                    if (valid) {
                        ruleFrom = true;
                    } else {
                        ruleFrom = false;
                    }
                });
            });
            // 下一步
            let off = true;
            this.fn(this.value);
            let checkUrlbody = {
                url_list: [],
            };
            this.linkList.forEach((item) => {
                if (item.url_name.length > 500) {
                    off = false;
                }
                // 添加检测参数
                checkUrlbody.url_list.push(item.url);
            });
            if (!off) {
                this.$message.error('链接名称应小于500字');
                return;
            }
            let vUrl = this.linkList.filter((item) => {
                return item.url_name && item.url;
            });
            if (vUrl < 1) {
                this.$message({
                    message: '请填写侵权链接',
                    type: 'error',
                });
                return;
            }
            let lastUrl = this.linkList.filter((item, index) => {
                return index === this.linkList.length - 1;
            });
            let urlList = this.linkList.filter((item, index) => {
                return index !== this.linkList.length - 1;
            });
            let urlOff = urlList.every((item) => {
                return item.url_name && item.url;
            });
            if (urlOff) {
                if (lastUrl[0]?.url_name === '' && lastUrl[0]?.url === '') {
                    urlOff = true;
                } else if (lastUrl[0]?.url_name === '' || lastUrl[0]?.url === '') {
                    urlOff = false;
                } else {
                    urlOff = true;
                }
            }
            let tortOff = false;
            if (this.tortShow) {
                tortOff = this.tortVal;
            } else {
                tortOff = true;
                this.tortVal = undefined;
            }
            if (this.value && this.textarea && urlOff && tortOff && actualRuleForm && ruleFrom) {
                // 信息填写完整
                // 请求后台校验，URL是否已投诉
                checkUrlList(checkUrlbody).then((res) => {
                    const { code, data, message } = res || {};
                    if (code === 200) {
                        const { check_ret_status, url_list, check_ret, check_url_own_ret } = data || {};
                        if (check_ret_status === 0) {
                            // 监测当前URL没问题，进行下一步跳转
                            // 重置异常URL列表
                            this.urlExceptionList = [];
                            this.checkUrlOwnMessage = '';
                            let obj = {
                                value: this.value, // 投诉产品
                                textarea: this.textarea, // 投诉问题描述
                                linkList: vUrl, // 投诉URL列表
                                icon: this.icon, // 投诉icon
                                tortVal: this.tortVal, // 侵权类型
                                actualUrl: this.originalLinkCenter.actualUrl, // 投诉原版链接
                                actualName: this.originalLinkCenter.actualName // 投诉原版链接标题
                            };
                            // 将表单信息缓存起来
                            sessionStorage.setItem('complaint', JSON.stringify(obj));
                            sessionStorage.setItem('active', 3);
                            this.$router.push({
                                path: '/verification',
                            });
                        } else {
                            // URL检测元素复制
                            this.urlExceptionList = url_list;
                            this.checkUrlOwnMessage = check_url_own_ret;
                            // 循环验证每一个URL表单
                            this.$refs.ruleForm.forEach((item) => {
                                item.validate();
                            });
                            this.$message({
                                message: check_ret !== null ? check_ret : '请按照要求填写内容',
                                type: 'error',
                            });
                        }
                    } else {
                        this.$message({
                            message: message,
                            type: 'error',
                        });
                    }
                });
            } else {
                this.$message({
                    message: '请将信息填写完整',
                    type: 'error',
                });
            }
        },
        clearLink() {
            // 清除重复链接
            var result = [];
            var obj = {};
            for (var i = 0; i < this.linkList.length; i++) {
                if (!obj[this.linkList[i].url]) {
                    result.push(this.linkList[i]);
                    obj[this.linkList[i].url] = true;
                }
            }
            this.linkList = result;
            this.currLink = this.linkList.filter((item, index) => {
                return index < this.showRows;
            });
            // 清空异常链接列表 重新检测
            this.urlExceptionList = [];
            this.cpage = 1;
        },
        handleRemove(file, fileList) {
            this.flag = false;
            this.fileList = [];
        },
        handleExceed(files, fileList) {
            this.$message({
                message: `最多上传 ${this.limit} 个文件。`,
                type: 'warning',
                offset: 88,
            });
        },
        handlePreview(file) {},
        handleFileSuccess(response, file, fileList) {
            this.$refs.upload.clearFiles();
            if (response.code === 500) {
                this.fileList = [];
                this.$message({
                    message: `上传失败，${response?.jsonError[0]?._exceptionMessageCode}`,
                    type: 'error',
                    offset: 88,
                });
            } else if (response.code === 200) {
                this._JnlNo = response._JnlNo;
                this.fileList.push(file);
                this.flag = true;
                this.id = this.linkList.length;
                response.data.forEach((item) => {
                    item.id = this.id++;
                });
                let num = 200 - this.linkList.length - 1;
                let loadList = response.data.filter((item, index) => {
                    return index <= num;
                });
                this.linkList.unshift(...loadList);
                this.currLink = this.linkList.filter((item, index) => {
                    return index < this.showRows;
                });
                this.cpage = 1;
                if (response.message === 'extract top 200') {
                    this.$message('上传的文件链接超过200条');
                } else {
                    this.$message({
                        message: '上传成功',
                        type: 'success',
                        offset: 88,
                    });
                }
            } else {
                this.fileList = [];
                this.$message.error('请按照模板格式上传投诉链接');
            }
        },
        beforeAvatarUpload(file) {
            var testmsg = file.name.substring(file.name.lastIndexOf('.') + 1);
            const extension = testmsg === 'xls';
            const extension2 = testmsg === 'xlsx';
            if (!extension && !extension2) {
                this.$message({
                    message: '上传文件只能是 xls、xlsx格式!',
                    type: 'warning',
                });
            }
            return extension || extension2;
        },
        addLink() {
            // 添加权限链接
            this.id = this.linkList.length;
            if (this.id === 200) {
                this.$message.error('投诉链接超过200条');
                return;
            }
            this.linkList.unshift({
                id: this.id++,
                url_name: '',
                url: '',
            });
            this.currLink = this.linkList.filter((item, index) => {
                return index < this.showRows;
            });
            this.cpage = 1;
        },
        changeC(e) {
            // 分页
            this.cpage = e;
            this.currLink = this.linkList.filter((item, index) => {
                return index >= this.showRows * (e - 1) && index <= this.showRows * (e - 1) + this.showRows;
            });
        },
        beforeUpload(file) {
            // 文件校验
            this.files = file;
            const extension = file.name.split('.')[1] === 'xls';
            const extension2 = file.name.split('.')[1] === 'xlsx';
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!extension && !extension2) {
                this.$message.warning('上传文件只能是 xls、xlsx格式!');
                return;
            }
            if (!isLt2M) {
                this.$message.warning('上传文件大小不能超过 2MB!');
                return;
            }
            this.fileName = file.name;
            return false; // 返回false不会自动上传
        },
        delLink(id) {
            // 删除前清空异常链接列表 重新检测
            this.urlExceptionList = [];
            // 删除
            this.linkList = this.linkList.filter((item) => {
                return item.id !== id;
            });
            this.currLink = this.currLink.filter((item) => {
                return item.id !== id;
            });
            this.currLink = this.linkList.filter((item, index) => {
                return index < this.showRows;
            });
            this.cpage = 1;
        },
    },
    mounted() {
        this.options = product;
        this.id = this.linkList.length;
        if (this.id === 0) {
            this.linkList.unshift({
                id: this.id++,
                url_name: '',
                url: '',
            });
            this.currLink = this.linkList.filter((item, index) => {
                return index < this.showRows;
            });
            this.cpage = 1;
        }
        let own = sessionStorage.getItem('ownership');
        own = JSON.parse(own);
        if (own.cp_id) {
            this.pdata = {
                name: own.workName,
                type: getType(own.workType),
                id: own.cp_id,
            };
        } else {
            this.pdata = {
                name: own.workName,
                type: getType(own.workType),
            };
        }
        let obj = sessionStorage.complaint;
        if (obj) {
            // 取缓存中的数据
            obj = JSON.parse(obj);
            this.value = obj.value;
            this.textarea = obj.textarea;
            this.linkList = obj.linkList;
            this.tortVal = obj.tortVal;
            this.originalLinkCenter.actualName = obj.actualName;
            this.originalLinkCenter.actualUrl = obj.actualUrl;
            this.currLink = this.linkList.filter((item, index) => {
                return index < this.showRows;
            });
            this.cpage = 1;
        }
        this.changeProduct();
        // if (location.hostname === 'localhost') {
        //     this.baseUrl = '/proxy';
        // } else if (location.hostname === '127.0.0.1') {
        //     this.baseUrl = '/proxy';
        // } else {
        //     this.baseUrl = location.origin;
        // }
    },
};
</script>
<style>
.el-select-dropdown__item.selected {
    color: #2d63e0;
}
</style>
<style lang='less' scoped>
::v-deep .link-list .el-form .inp-url {
    width: 288px;
}
[class*=' el-icon-'],
[class^='el-icon-']:hover {
    color: #c0c4cc;
}
::v-deep .el-pagination [class*=' el-icon-'],
[class^='el-icon-']:hover {
    color: #c0c4cc;
}
::v-deep .el-pagination .btn-prev:disabled {
    background: #fafbfb !important;
}
::v-deep .el-pagination .btn-next:disabled {
    background: #fafbfb !important;
}
.required-icon {
    color: red;
    line-height: 16px;
    vertical-align: sub;
}
.complaint {
    width: 1200px;
    margin: 0 auto;
    background: #ffffff;
    border-radius: 4px;
    text-align: left;
    .next-btn {
        padding-left: 160px;
    }
    .complaint-wrap .line {
        line-height: 40px;
    }
    .wrap-item {
        margin-top: 28px;
        ::v-deep .el-pagination {
            text-align: center;
        }
        ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
            background: rgba(45, 99, 224, 0.2);
            border: 1px solid #2d63e0;
            border-radius: 5px;
            color: #2d63e0;
        }
        ::v-deep .el-pagination.is-background .el-pager li:not(.disabled):hover {
            color: #2d63e0;
        }
        ::v-deep .el-pagination.is-background .el-pager li:not(.disabled) {
            background: transparent;
            border: 1px solid #e2e5eb;
            border-radius: 6px;
        }
        ::v-deep .el-pagination.is-background .btn-next {
            background: transparent;
            border: 1px solid #e2e5eb;
            border-radius: 6px;
        }
        ::v-deep .el-pagination.is-background .btn-prev {
            background: transparent;
            border: 1px solid #e2e5eb;
            border-radius: 6px;
        }
        ::v-deep .el-pagination.is-background .el-pager li {
            background: transparent;
            border: 1px solid #e2e5eb;
            border-radius: 6px;
        }
        ::v-deep .el-select {
            width: 500px;
        }
        ::v-deep .el-upload__tip a {
            font-size: 12px;
            color: #2d63e0;
        }
        ::v-deep .el-upload__tip {
            font-size: 12px;
            color: #b0b2c4;
        }
        ::v-deep .el-button--primary {
            background: #f5f7fc;
            font-size: 14px;
            color: #2d63e0;
            border: none;
        }
        ::v-deep textarea {
            resize: none;
            word-wrap: break-word;
            word-break: break-all;
        }
        .upload {
            display: flex;
            .title {
                display: inline-block;
                width: 150px;
                text-align: right;
                line-height: 30px;
                margin-right: 10px;
                vertical-align: top;
                font-size: 14px;
                color: #383d47;
            }
        }
        .top {
            width: 668px;
            display: flex;
            justify-content: space-between;
            p {
                display: inline-block;
                width: 150px;
                text-align: right;
                margin-right: 10px;
                vertical-align: top;
                font-size: 14px;
                color: #383d47;
            }
            .clear {
                color: #767981;
                i {
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    background: url(../assets/img/clear.svg) no-repeat;
                    background-size: 100% 100%;
                    position: relative;
                    top: 5px;
                }
                &:hover {
                    cursor: pointer;
                    color: #e63232;
                    i {
                        background: url(../assets/img/hoverclear.png) no-repeat;
                        background-size: 100% 100%;
                    }
                }
                &:active {
                    cursor: pointer;
                    color: #cc2d2d;
                    i {
                        background: url(../assets/img/activeclear.png) no-repeat;
                        background-size: 100% 100%;
                    }
                }
            }
        }
        .bottom {
            width: 640px;
            margin-left: 90px;
            .add-link {
                width: 500px;
                height: 32px;
                border: 1px dotted #c7c9d5;
                border-radius: 4px;
                text-align: center;
                line-height: 32px;
                font-size: 14px;
                color: #1e2024;
                margin: 20px auto;
                span:nth-child(1) {
                    display: inline-block;
                    width: 20px;
                    height: 20px;
                    background: url(../assets/img/add.svg) no-repeat 100% 100%;
                    background-size: 100% 100%;
                    position: relative;
                    top: 5px;
                }
                &:hover {
                    cursor: pointer;
                    color: #2d63e0;
                    span:nth-child(1) {
                        background: url(../assets/img/addhover@2x.png) no-repeat 100% 100%;
                        background-size: 100% 100%;
                    }
                }
                &:active {
                    cursor: pointer;
                    color: #2858c7;
                    span:nth-child(1) {
                        background: url(../assets/img/addactive@2x.png) no-repeat 100% 100%;
                        background-size: 100% 100%;
                    }
                }
            }
            .link-list {
                width: 500px;
                margin: 10px auto;
                .el-form {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 10px;
                    ::v-deep .el-form-item:nth-child(1) {
                        width: 170px;
                        height: 32px;
                    }
                    ::v-deep .el-form-item:nth-child(2) {
                        height: 32px;
                        // .el-input__inner:hover {
                        //     cursor: pointer;
                        //     color: #2D63E0;
                        // }
                    }
                    i {
                        display: inline-block;
                        width: 20px;
                        height: 20px;
                        background: url(../assets/img/del.png) no-repeat;
                        background-size: 100% 100%;
                        position: relative;
                        top: 10px;
                        &:hover {
                            background: url(../assets/img/delstop.png) no-repeat;
                            background-size: 100% 100%;
                            cursor: pointer;
                        }
                        &:active {
                            background: url(../assets/img/cleardown.png) no-repeat;
                            background-size: 100% 100%;
                            cursor: pointer;
                        }
                    }
                }
            }
        }
        > p {
            display: inline-block;
            width: 150px;
            text-align: right;
            margin-right: 10px;
            vertical-align: top;
            font-size: 14px;
            color: #383d47;
        }
        .right {
            display: inline-block;
            width: 500px;
        }
    }
    .original {
        p {
            vertical-align: bottom;
            line-height: 50px;
        }
        .link-list {
            width: 500px;
            .el-form {
                display: flex;
                justify-content: space-between;
                margin-bottom: 10px;
                ::v-deep .el-form-item:nth-child(1) {
                    width: 170px;
                    height: 32px;
                    margin: 0 10px 0 0;
                }
                ::v-deep .el-form-item:nth-child(2) {
                    height: 32px;
                    width: 310px;
                    margin: 0;
                }
            }
        }
    }
    .wrap-tips {
        display: flex;
        justify-content: space-around;
        width: 660px;
        height: 45px;
        background: #f5f7fc;
        border-radius: 4px;
        font-size: 13px;
        color: #383d47;
        margin-top: 30px;
        li {
            line-height: 45px;
        }
    }
    .wrap-title {
        margin-top: 24px;
        i {
            display: inline-block;
            width: 11px;
            height: 11px;
            background: url(../assets/img/title2.png) no-repeat 100% 100%;
            background-size: 100% 100%;
        }
        h1 {
            display: inline-block;
            font-weight: bold;
            font-size: 18px;
            margin-left: 5px;
        }
    }
    .complaint-wrap {
        width: 1000px;
        min-height: 100vh;
        margin-left: 200px;
    }
}
</style>